import {
	createApp
} from 'vue'
import {
	Form,
	Field,
	CellGroup,
	Button,
	Picker,
	Popup,
	Notify,
	Loading  
} from 'vant'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import 'vant/lib/index.css';
import wx from 'weixin-js-sdk';
createApp(App).use(store).use(Form).use(Button).use(Field).use(CellGroup).use(router).use(Picker).use(Popup).use(Notify).use(Loading).mount('#app')
