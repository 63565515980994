import {
	createRouter,
	createWebHashHistory,
	createWebHistory
} from 'vue-router'


const routes = [{
	path: '/',
	name: 'home',
	component: () => import('@/views/home.vue')
},{
	path:'/result',
	name:'result',
	component:()=>import('@/views/result.vue')
}]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

export default router